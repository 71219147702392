.share {
  width: 90%;
  margin: auto;
  border-radius: 20px;
  margin-bottom: 20px;
  border: 2px solid var(--darkListOutlineColor);
  box-shadow: 1px 5px 15px 0px black;
  background: rgb(255, 255, 255);
}

.dark-version .share {
  background: var(--darkBgColor);
  box-shadow: 1px 5px 15px 0px var(--darkMainColor);
  border: 1px solid var(--darkListOutlineColor);
  color: var(--darkColorPost);
}

.shareWrapper {
  padding: 10px;
}

.shareTop {
  display: flex;
  align-items: center;
}

.shareProfileImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.shareInput {
  font-size: 17px;
  border: none;
  width: 80%;
  background: none;
}

.dark-version .shareInput {
  color: #fff;
}

.shareInput::placeholder {
  color: #999;
}

.shareInput:focus {
  outline: none;
}

.shareHr {
  margin: 20px;
}

.shareBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shareOptions {
  display: flex;
  margin-left: 20px;
}

.shareOption {
  display: flex;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;
}

.shareIcon {
  font-size: 18px;
  margin-right: 3px;
}

.shareButton {
  border: none;
  padding: 10px;
  border-radius: 7px;
  background: green;
  font-weight: 500;
  margin-right: 20px;
  cursor: pointer;
  color: #fff;
  transition: 1s;
}

.shareButton:focus {
  outline: none;
}

.shareButton {
  --c: #fff;
  background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p, 100%)/300% no-repeat,
    var(--lightMainColor);
  color: #0000;
  border: none;
  transform: perspective(500px) rotateY(calc(20deg*var(--_i, -1)));
  text-shadow: calc(var(--_i, -1)* 0.08em) -.01em 0 var(--c),
    calc(var(--_i, -1)*-0.08em) .01em 2px #0004;
  outline-offset: .1em;
  transition: 0.3s;
}

.dark-version .shareButton {
  background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p, 100%)/300% no-repeat,
    var(--darkMainColor);

}

.shareButton:hover,
.shareButton:focus-visible {
  --_p: 0%;
  --_i: 1;
}

.shareButton:active {
  text-shadow: none;
  color: var(--c);
  box-shadow: inset 0 0 9e9q #0005;
  transition: 0s;
}

.shareButton {
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
  padding: 5px 10px;
  margin-right: 20px;
}

.shareImgContainer {
  padding: 0 20px 10px 20px;
  position: relative;
}

.shareImg {
  width: 100%;
  object-fit: cover;
  margin-top: 10px;
  border-radius: 0px;
}

.shareCancelImg {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  opacity: 0.7;
  font-size: 30px !important;
  color: #fff;
}