.login {
  width: 100vw;
  height: 100vh;
  background: #f0f2f5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginWrapper {
  width: 80%;
  height: 80%;
  display: flex;
  padding: 40px;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.loginLeft, .loginRight {
  flex: 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  user-select: none;
}

.loginLeft {
  display: flex;
  justify-content: center;
}

.loginRight {
  text-align: end;
}

.loginLogo {
  font-size: 70px;
  font-weight: 800;
  color: #1775ee;
  margin-bottom: 20px;
}

.loginDesc {
  font-size: 24px;
  padding-right: 50px;
}

.loginBox {
  height: 400px;
  width: 90%;
  margin: auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  text-align: center;
}

.loginInput {
  height: 50px;
  border-radius: 15px;
  border: 1px solid gray;
  font-size: 18px;
  padding-left: 20px;
  margin-bottom: 10px;
}

.loginInput:focus {
  outline: none;
  border: 2px solid #1775ee;
}

.loginButton {
  height: 50px;
  border-radius: 10px;
  border: none;
  background-color: #1775ee;
  color: white;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 20px;
}

.loginButton:focus, .loginRegisterButton:focus {
  outline: none;
}

.loginButton:disabled, .loginRegisterButton:disabled {
  cursor: not-allowed;
}

.loginForgot {
  text-align: center;
  color: #1775ee;
}

.loginRegisterButton {
  width: 60%;
  align-self: center;
  height: 50px;
  border-radius: 10px;
  border: none;
  background-color: #42b72a;
  color: white;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}

.loginHr {
  margin: 10px 0;
}