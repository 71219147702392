.profile {
  flex: 1;
  display: flex;
  height: 100vh;
  background: var(--lightModeBgGradient);
}

.profile.dark-version {
  background: var(--darkRightBgColor);
  color: #fff;
}

.profileRight {
  overflow-y: scroll;
  width: calc(100% - 300px);
}

.profileCover {
  width: 95%;
  margin: 20px auto;
  height: 320px;
  position: relative;
}

.profileCoverImg {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 20px;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.profileUserImg {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid white;
}

.uploadProfilePicCont {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 150px;
  text-align: center;
}

.dark-version .profileUserImg {
  border: 3px solid var(--darkRightBgColor);
  background: var(--darkRightBgColor);
}

.profileInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profileInfoName {
  font-size: 24px;
}

.profileInfoDesc {
  font-weight: 300;
  text-align: center;
  margin-top: 10px;
}

.profileRightBottom {
  display: flex;
}

.uploadCoverPic {
  position: absolute;
  left: 10px;
  top: 10px;
  cursor: pointer;
  border-radius: 10px;
  padding: 3px 5px;
  background: rgba(255, 255, 255, 0.4);
  color: rgb(0, 0, 0);
  transition: 1s;
  cursor: pointer;
}

.uploadCoverPic:hover {
  color: rgb(255, 255, 255);
}

.uploadProfilePic {
  position: absolute;
  left: 42%;
  bottom: 20px;
  cursor: pointer;
  padding: 5px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.4);
  color: black;
  transition: 1s;
  cursor: pointer;
}

.uploadCoverPicButton {
  font-size: 35px !important;
  cursor: pointer;
}

.uploadProfilePicButton {
  font-size: 25px !important;
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .profileRight {
    width: calc(100% - 100px);
  }
}