.rightbarFriend {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 15px 5px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  transition: 1s;
}

.rightbarProfileImgContainer {
  position: relative;
  margin-right: 10px;
}

.rightbarProfileImg {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
}

.rightbarOnline {
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: limegreen;
  top: -2px;
  right: 0;
  border: 2px solid white;
}

.usersLink {
  text-decoration: none;
  color: white;
}